import { Dispatch, SetStateAction } from 'react';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { EnumToArray } from '@/utils/common';

import { INPUT_ERRORS } from '.';

export const STEPPER_STEPS = [
  'Personal Info',
  'Academic History',
  'Training Seminars',
  'Work Experience',
  'Special Skills',
  'Others',
];

export enum RECRUITMENT_SOURCE {
  PLATFORM = 'Platforms',
  LRA = 'LRA',
}
export enum PLATFORM_SOURCE {
  FACEBOOK = 'SRC_FB',
  INDEED = 'SRC_INDEED',
  JOBSTREET = 'SRC_JOBST',
  BLUESHIRT = 'SRC_BSHIRT',
  JORA = 'SRC_JORA',
  REFERRAL = 'SRC_REF',
  OTHER = 'SRC_OTHER',
}
export enum LOCAL_RECRUITMENT_ACTIVITY_SOURCE {
  PESO_OFFICE = 'LRA_PESO',
  JOB_FAIR = 'LRA_JOBF',
  MEGA_JOB_FAIR = 'LRA_MJFAIR',
  BARANGAY_JOB_FAIR = 'LRA_BJFAIR',
  INHOUSE_JOB_FAIR = 'LRA_INHOUSE',
  SMI_LIVE_JOB_HIRING = 'LRA_SMI',
  FLYERINGS = 'LRA_FLYER',
  SCHOOL_JOB_FAIR = 'LRA_SCHFAIR',
  OTHER = 'LRA_OTHER',
}

export const RECRUITMENT_SOURCE_DROPDOWN_OPTIONS = [
  {
    label: 'Platforms',
    value: RECRUITMENT_SOURCE.PLATFORM,
  },
  {
    label: 'LRA',
    value: RECRUITMENT_SOURCE.LRA,
  },
];

export const PLATFORM_SOURCE_DROPDOWN_OPTIONS = [
  {
    label: 'Facebook',
    value: PLATFORM_SOURCE.FACEBOOK,
  },
  {
    label: 'Indeed',
    value: PLATFORM_SOURCE.INDEED,
  },
  {
    label: 'JobStreet',
    value: PLATFORM_SOURCE.JOBSTREET,
  },
  {
    label: 'Blueshirt',
    value: PLATFORM_SOURCE.BLUESHIRT,
  },
  {
    label: 'Jora',
    value: PLATFORM_SOURCE.JORA,
  },
  {
    label: 'Referral',
    value: PLATFORM_SOURCE.REFERRAL,
  },
  {
    label: 'Other',
    value: PLATFORM_SOURCE.OTHER,
  },
];

export const LOCAL_RECRUITMENT_ACTIVITY_SOURCE_DROPDOWN_OPTIONS = [
  {
    label: 'PESO Office',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.PESO_OFFICE,
  },
  {
    label: 'Job Fair',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.JOB_FAIR,
  },
  {
    label: 'Mega Job Fair',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.MEGA_JOB_FAIR,
  },
  {
    label: 'Barangay Job Fair',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.BARANGAY_JOB_FAIR,
  },
  {
    label: 'In-House Job Fair',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.INHOUSE_JOB_FAIR,
  },
  {
    label: 'SMI Live Job Hiring',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SMI_LIVE_JOB_HIRING,
  },
  {
    label: 'Flyerings',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.FLYERINGS,
  },
  {
    label: 'School Job Fair',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SCHOOL_JOB_FAIR,
  },
  {
    label: 'Other',
    value: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.OTHER,
  },
];

export enum STATISTICS_DATA {
  ENDORSED = 'Endorsed',
  ON_PROCESS = 'On Process',
  BACKED_OUT = 'Backed Out',
  PLACED = 'Placed',
  REPLACEMENT = 'Replacement',
  ON_CALL = 'On Call',
  TEMPORARY_DEPLOYED = 'Temporary Deployed',
  OTHER = 'Other',
}

const GENDER_DROPDOWN_OPTIONS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const SOURCE_TYPE = [];

export const generateProfileFields = (
  department: IDropdownOption[],
  loadingDepartments: boolean | null,
  haveMoreDepartmentPage: boolean | null,
  setDepartmentPage: Dispatch<SetStateAction<number>>,
  setDepartmentSearch: Dispatch<SetStateAction<string>>,
  religionList?: IDropdownOption[] | null,
  loadingReligions?: boolean | null,
  sourceList?: IDropdownOption[],
  disabledSourceList?: boolean,
  countryList?: IDropdownOption[],
  cityList?: IDropdownOption[],
  setSearchCountry?: React.Dispatch<React.SetStateAction<string>>,
  setSearchCity?: React.Dispatch<React.SetStateAction<string>>,
  cityDisabled?: boolean,
  searchCountry?: string,
  searchCity?: string,
) => {
  return [
    {
      name: 'positionDesired',
      title: 'Position Desired',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.POSITION_DESIRED.required,
    },
    {
      name: 'expectedSalary',
      title: 'Expected Salary',
      isRequired: true,
      isNumber: true,
      isRequiredMessage: INPUT_ERRORS.EXPECTED_SALARY.required,
    },
    {
      name: 'nickname',
      title: 'Nick Name',
    },
    {
      name: 'contactNo',
      title: 'Contact No.',
      isRequired: true,
      contactNumber: true,
      isRequiredMessage: INPUT_ERRORS.CONTACT_NO.required,
    },
    {
      name: 'presentAddress',
      title: 'Present Address',
      colSpan: 2,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.PRESENT_ADDRESS.required,
    },
    {
      name: 'civilStatus',
      title: 'Civil Status',
      isDropdown: true,
      dropdownOptions: EnumToArray(CIVIL_STATUS),
    },
    {
      name: 'permanentAddress',
      title: 'Permanent Address',
      colSpan: 2,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.PERMANENT_ADDRESS.required,
    },

    {
      name: 'birthPlace',
      title: 'Birth Place',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.BIRTH_PLACE.required,
    },
    {
      name: 'birthDate',
      title: 'Birth Date',
      isDate: true,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.BIRTH_DATE.required,
    },
    {
      name: 'age',
      title: 'Age',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.AGE.required,
    },
    {
      name: 'nationality',
      title: 'Nationality',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.NATIONALITY.required,
    },
    {
      name: 'country',
      title: 'Country',
      isRequired: true,
      isDropdown: true,
      isRequiredMessage: INPUT_ERRORS.COUNTRY.required,
      dropdownOptions: countryList,
      props: {
        saveBothLabelAndValue: true,
        setInputSearch: setSearchCountry,
        isSearchable: true,
        searchValue: searchCountry,
      },
    },
    {
      name: 'city',
      title: 'City',
      isRequired: true,
      isDropdown: true,
      dropdownOptions: cityList,
      props: {
        saveBothLabelAndValue: true,
        setInputSearch: setSearchCity,
        isSearchable: true,
        searchValue: searchCity,
      },
      isDisabled: cityDisabled,
    },
    {
      name: 'zipCode',
      title: 'ZipCode',
      isRequiredMessage: INPUT_ERRORS.ZIP_CODE.required,

      isRequired: true,
    },
    {
      name: 'religion',
      title: 'Religion',
      isRequired: true,
      isDropdown: true,
      dropdownOptions: religionList,
      isLoading: loadingReligions,
      isRequiredMessage: INPUT_ERRORS.RELIGION.required,
    },
    {
      name: 'sex',
      title: 'Sex',
      isDropdown: true,
      dropdownOptions: GENDER_DROPDOWN_OPTIONS,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.SEX.required,
    },
    {
      name: 'sourceType',
      title: 'Source Type',
      isRequired: true,
      isDropdown: true,
      isRequiredMessage: INPUT_ERRORS.SOURCE_TYPE.required,
      dropdownOptions: RECRUITMENT_SOURCE_DROPDOWN_OPTIONS,
    },
    {
      name: 'source',
      title: 'Source Name',
      isRequired: true,
      isDropdown: true,
      isRequiredMessage: INPUT_ERRORS.SOURCE_NAME.required,
      dropdownOptions: sourceList,
      isDisabled: disabledSourceList,
    },
    {
      name: 'height',
      title: 'Height (feet)',

      isNumber: true,
    },
    {
      name: 'weight',
      title: 'Weight (kg)',
      isNumber: true,
    },
    {
      name: 'sssNo',
      title: 'SSS No.',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.SSS_NO.required,
    },
    {
      name: 'tin',
      title: 'TIN',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.TIN.required,
    },
    {
      name: 'philHealthNo',
      title: 'Philhealth No.',
      isRequiredMessage: INPUT_ERRORS.PHIL_HEALTH_NO.required,
      isRequired: true,
    },
    {
      name: 'hdmfNo',
      title: 'PAG-IBIG (HDMF) No.',
      isRequiredMessage: INPUT_ERRORS.HDMF_NO.required,
      isRequired: true,
    },
    {
      name: 'department',
      title: 'Department',
      isDropdown: true,
      dropdownOptions: department,
      isLoading: loadingDepartments,
      props: {
        haveMoreOptions: !!haveMoreDepartmentPage,
        setPage: setDepartmentPage,
        setInputSearch: setDepartmentSearch,
        isSearchable: true,
        saveBothLabelAndValue: true,
      },
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.DEPARTMENT.required,
    },
    {
      name: 'nameOfSpouse',
      title: 'Name of Spouse',
    },
    {
      name: 'occupationOfSpouse',
      title: 'Occupation',
      isTwo: true,
    },
    {
      name: 'nameOfMother',
      title: 'Name of Mother',
    },
    {
      name: 'occupationOfMother',
      title: 'Occupation',
      isTwo: true,
    },
    {
      name: 'nameOfFather',
      title: 'Name of Father',
    },
    {
      name: 'occupationOfFather',
      title: 'Occupation',
      isTwo: true,
    },
    {
      name: 'distinguishMarksFeatures',
      title: 'Any Distinguishing Marks/Features',
      colSpan: 3,
    },
  ];
};

export const ACADEMIC_HISTORY_FIELDS = [
  {
    name: 'college',
    title: 'College',
  },
  {
    name: 'collegeYearCovered',
    title: 'Year Covered',
    isNumber: true,
  },
  {
    name: 'collegeCourse',
    title: 'Course',
  },
  {
    name: 'vocational',
    title: 'Vocational',
  },
  {
    name: 'vocationalYearCovered',
    title: 'Year Covered',
    isNumber: true,
  },
  {
    name: 'vocationalCourse',
    title: 'Course',
  },
  {
    name: 'highSchool',
    title: 'High School',
  },
  {
    name: 'highSchoolYearCovered',
    title: 'Year Covered',
    isNumber: true,
  },
  {
    name: 'highSchoolCourse',
    title: 'Course',
  },
  {
    name: 'elementary',
    title: 'Elementary',
  },
  {
    name: 'elementaryYearCovered',
    title: 'Year Covered',
    isNumber: true,
  },
  {
    name: 'elementaryCourse',
    title: 'Course',
  },
];

export const TRAINING_SEMINAR_FIELDS = [
  {
    name: 'courseTitle',
    title: 'Course Title',
  },
  {
    name: 'inclusiveDates',
    title: 'Inclusive Dates',
  },
  {
    name: 'venue',
    title: 'Venue',
  },
];

export const WORK_EXPERIENCE_FIELDS = [
  {
    name: 'nameOfCompany',
    title: 'Name of Company',
  },
  {
    name: 'position',
    title: 'Position',
  },
  {
    name: 'salary',
    title: 'Salary',
    isNumber: true,
  },
  {
    name: 'startDate',
    title: 'Start Date',
    isDate: true,
  },
  {
    name: 'endDate',
    title: 'End Date',
    isDate: true,
  },
];

export const WORKING_RELATIVES_FIELDS = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'relationship',
    title: 'Relationship',
  },
];

export const EMERGENCY_CONTACT_FIELDS = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'relationship',
    title: 'Relationship',
  },
  {
    name: 'address',
    title: 'Address',
  },
  {
    name: 'telNo',
    title: 'Tel No.',
    isNumber: true,
  },
];
export const REFERENCES_FIELDS = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'relationship',
    title: 'Relationship',
  },
  {
    name: 'address',
    title: 'Address',
  },
  {
    name: 'contactNo',
    title: 'Tel No.',
    isNumber: true,
  },
];
const displaySourceTypeOtion = (user: IUser) => {
  if (
    EnumToArray(LOCAL_RECRUITMENT_ACTIVITY_SOURCE).includes(
      user?.personalInfo?.source as string,
    )
  ) {
    return RECRUITMENT_SOURCE_DROPDOWN_OPTIONS.find(
      (item) => item.value === RECRUITMENT_SOURCE.LRA,
    );
  } else if (
    EnumToArray(PLATFORM_SOURCE).includes(user?.personalInfo?.source as string)
  ) {
    return RECRUITMENT_SOURCE_DROPDOWN_OPTIONS.find(
      (item) => item.value === RECRUITMENT_SOURCE.PLATFORM,
    );
  }
  return RECRUITMENT_SOURCE_DROPDOWN_OPTIONS.find(
    (item) =>
      item.value !== RECRUITMENT_SOURCE.PLATFORM &&
      item.value !== RECRUITMENT_SOURCE.LRA,
  );
};

export const createPersonalInfoData = (
  user: IUser,
  countryList: IDropdownOption[],
) => {
  const findCountry = countryList?.find(
    (item) => item.label === user?.personalInfo?.country,
  );
  return {
    ...user?.personalInfo,
    positionDesired: user?.personalInfo?.positionDesired?.[0] || '',
    avatar: user?.personalInfo?.avatar || user?.avatar || '',
    resume: user?.personalInfo?.resume || '',
    department:
      (user?.personalInfo?.department as { name: string })?.name ||
      user?.personalInfo?.department,
    ...(Array.isArray(user?.personalInfo?.familyEducationDetails) && {
      nameOfSpouse:
        user?.personalInfo.familyEducationDetails[0]?.nameOfSpouse || '',
      occupationOfSpouse:
        user?.personalInfo.familyEducationDetails[0]?.occupation || '',
      nameOfFather:
        user?.personalInfo.familyEducationDetails[1]?.nameOfFather || '',
      occupationOfFather:
        user?.personalInfo.familyEducationDetails[1]?.occupation || '',
      nameOfMother:
        user?.personalInfo.familyEducationDetails[2]?.nameOfMother || '',
      occupationOfMother:
        user?.personalInfo.familyEducationDetails[2]?.occupation || '',
      sourceType: displaySourceTypeOtion(user)?.value,
      source: user?.personalInfo?.source || '',
      country: findCountry,
      city: user?.personalInfo?.city || '',
      zipCode: user?.personalInfo?.zipCode || '',
      height: user?.personalInfo?.height || 0,
      weight: user?.personalInfo?.weight || 0,
      distinguishMarksFeatures: user?.personalInfo?.distinguishMarksFeatures,
    }),
  };
};
export enum CIVIL_STATUS {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  WIDOWED = 'Widowed',
  SEPARATED = 'Separated',
  OTHER = 'Other',
}
