import React from 'react';

// import { IResponsePercentage, IStatisticsPercentage } from '@/@types/dashboard';
import Container from '@/components/Container';
// import CustomPieChart from '@/components/CustomPieChart';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
// responsePercentageDetail,
('@/constants/dashboard');
import { generatePath, useNavigate } from 'react-router-dom';

import { IAppliedJob } from '@/@types/dashboard';
import { Separator } from '@/components/separator';
import { DASHBOARD_TABS } from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
// import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { getSliderData } from '@/services/dashboard';
// import {
//   getJobAppliedPercentage,
//   getResponsePercentage,
// } from '@/services/dashboard';
import { getTagVariant } from '@/utils/common';

import JobsSection from './components/Jobs';
import ProfileStatus from './components/ProfileStatus';

const Dashboard = () => {
  const staticText = strings.dashboard;

  const navigate = useNavigate();

  const { data, isLoading } = useGenericQuery(['applied-jobs'], () =>
    getSliderData(),
  );

  // const { data: appliedJobPercentage, isLoading: appliedJobLoading } =
  //   useGenericQuery(['applied-job-percentage'], () =>
  //     getJobAppliedPercentage(),
  //   );

  // const { data: responsePercentage, isLoading: responsePercentageLoading } =
  //   useGenericQuery(['response-percentage'], () => getResponsePercentage());

  const jobsApplied = !isLoading && !!(data as IAppliedJob[]).length;

  return (
    <>
      <ProfileStatus />
      <div
        className={cn(
          'md:pt-6 xl:grid xl:grid-cols-3 gap-5 space-y-5 xl:space-y-0 min-h-[calc(100vh)]',
          {
            '!min-h-[calc(100vh-250px)]': window.innerHeight > 1000,
          },
        )}
      >
        <Container
          className={cn('md:px-5 md:py-6 col-span-3', {
            'col-span-2': jobsApplied,
          })}
        >
          <div className='flex flex-col gap-5 h-full'>
            {/* <ProfileStatus /> */}
            <div className='flex-grow overflow-hidden'>
              <JobsSection jobsApplied={jobsApplied} />
            </div>
          </div>
        </Container>
        {/* <div className='flex flex-col md:flex-row xl:flex-col gap-5 h-full'> */}
        {jobsApplied ? (
          <Container className='gap-3 flex flex-col w-full md:w-full px-6 max-h-fit'>
            <div className='flex flex-col gap-5'>
              <Typography className='font-bold block' variant='xl'>
                {staticText.listOfJobStatusApplied}
              </Typography>
            </div>
            <div className='w-full flex flex-col gap-1 justify-center mt-4'>
              {isLoading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <div key={index} className='flex justify-between gap-3'>
                      <Skeleton className='h-6 w-2/5' />
                      <Skeleton className='h-6 w-1/5' />
                    </div>
                  ))
                : Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <>
                      <div
                        onClick={() => {
                          const path = generatePath(ROUTES.JOB_DETAILS, {
                            tab: DASHBOARD_TABS.APPLIED,
                            id: item.ticket,
                          });
                          navigate(path);
                        }}
                        className='cursor-pointer'
                      >
                        <Typography
                          key={item.jobTitle}
                          className='flex justify-between font-bold text-primary text-lg'
                        >
                          {`Job#${item.ticketNumber}`}
                        </Typography>
                      </div>
                      <div className='flex justify-between'>
                        <Typography
                          key={item.jobTitle}
                          className='flex gap-2 justify-between font-bold w-11/12'
                        >
                          {item.jobTitle}
                        </Typography>
                        <Tags
                          text={item.status}
                          variant={getTagVariant(item.status)}
                          containerClassName='h-7 w-36'
                        />
                      </div>
                      {index < data.length - 1 ? (
                        <Separator className='my-3' />
                      ) : null}
                    </>
                  ))}
            </div>
          </Container>
        ) : null}
        {/* <Container className='gap-3 flex flex-col justify-between w-full md:w-1/2 xl:w-full'>
          <div className='flex flex-col gap-5'>
            <Typography className='font-bold block' variant='xl'>
              {staticText.jobsApplied}
            </Typography>
            <Typography className='md:text-sm'>
              {staticText.jobsAppliedDesc}
            </Typography>
          </div>
          <div className='w-full flex flex-col justify-center items-center'>
            <CustomPieChart
              data={appliedJobDetail(
                appliedJobPercentage as IStatisticsPercentage,
              )}
              outerRadius={120}
              innerRadius={60}
              isHalf={true}
              height={190}
              width={350}
              isLoading={appliedJobLoading}
            />
          </div>
        </Container>
        <Container className='flex flex-col justify-between w-full md:w-1/2 xl:w-full'>
          <div className='gap-6 flex flex-col'>
            <Typography className='font-bold block' variant='xl'>
              {staticText.response}
            </Typography>
            <Typography className='md:text-sm'>
              {staticText.responseDesc}
            </Typography>
          </div>
          <div className='w-full flex flex-col justify-center items-center'>
            <CustomPieChart
              data={responsePercentageDetail(
                responsePercentage as IResponsePercentage,
              )}
              outerRadius={100}
              innerRadius={55}
              height={250}
              width={250}
              isHalf={false}
              isLoading={responsePercentageLoading}
            />
          </div>
        </Container> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default Dashboard;
