import { BiSolidDollarCircle } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';

import { IDropdownOption } from '@/@types';
import Suitcase from '@/assets/svgs/suitcase.svg';
import Team from '@/assets/svgs/team.svg';
import { strings } from '@/locales';
import { ISlide } from '@/pages/Auth/components/SignupJobCard';
import { academicHistory } from '@/provider/FormProvider/types';
import { EnumToArray } from '@/utils/common';

import { CIVIL_STATUS } from './profile';
import { INPUT_ERRORS } from '.';

export const generateSignUpFields = (
  countryList?: IDropdownOption[],
  cityList?: IDropdownOption[],
  setSearchCountry?: React.Dispatch<React.SetStateAction<string>>,
  setSearchCity?: React.Dispatch<React.SetStateAction<string>>,
  cityDisabled?: boolean,
  searchCountry?: string,
  searchCity?: string,
) => {
  return [
    {
      name: 'firstName',
      title: 'First Name',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.FIRST_NAME.required,
    },
    {
      name: 'lastName',
      title: 'Last Name',
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.LAST_NAME.required,
    },
    {
      name: 'email',
      title: 'Email',
      fullWidth: true,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.EMAIL.required,
    },
    {
      name: 'civilStatus',
      title: 'Civil Status',
      isDropdown: true,
      dropdownOptions: EnumToArray(CIVIL_STATUS),
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.CIVIL_STATUS.required,
    },
    {
      name: 'contactNo',
      title: 'Contact No.',
      isRequired: true,
      contactNumber: true,
      isRequiredMessage: INPUT_ERRORS.CONTACT_NO.required,
    },
    {
      name: 'birthDate',
      title: 'Birth Date',
      isDate: true,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.BIRTH_DATE.required,
    },
    {
      name: 'age',
      title: 'Age',
      isRequired: true,
      isDisabled: true,
      isRequiredMessage: INPUT_ERRORS.AGE.required,
    },
    {
      name: 'country',
      title: 'Country',
      isRequired: true,
      isDropdown: true,
      isRequiredMessage: INPUT_ERRORS.COUNTRY.required,
      dropdownOptions: countryList,
      props: {
        saveBothLabelAndValue: true,
        setInputSearch: setSearchCountry,
        isSearchable: true,
        searchValue: searchCountry,
      },
    },
    {
      name: 'city',
      title: 'City',
      isRequired: !cityDisabled,
      isRequiredMessage: !cityDisabled ? INPUT_ERRORS.CITY.required : '',
      isDropdown: true,
      dropdownOptions: cityList,
      props: {
        saveBothLabelAndValue: true,
        setInputSearch: setSearchCity,
        isSearchable: true,
        searchValue: searchCity,
      },
      isDisabled: cityDisabled,
    },
    {
      name: 'block',
      title: 'Block/Unit/Number',
      fullWidth: true,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.BLOCK_OR_UNIT.required,
    },
    {
      name: 'password',
      title: 'Password',
      isRequired: true,
      isPassword: true,
      isRequiredMessage: INPUT_ERRORS.PASSWORD.required,
    },
    {
      name: 'reEnterPassword',
      title: 'Confirm Password',
      isPassword: true,
      isRequired: true,
      isRequiredMessage: INPUT_ERRORS.CONFIRM_PASSWORD.required,
    },
  ];
};
export const initialValues = {
  firstName: '',
  lastName: '',
  civilStatus: '',
  email: '',
  contactNo: '',
  birthDate: '',
  age: 0,
  block: '',
  city: '',
  country: '',
  additionalInfo: {
    expectedSalary: 0,
    workExperiences: [
      {
        nameOfCompany: '',
        position: '',
        salary: null,
        startDate: '',
        endDate: '',
        reasonForLeaving: '',
      },
    ],
    academicHistory: academicHistory,
  },
  password: '',
  reEnterPassword: '',
};

export const SLIDES_CONTENT = (slide: ISlide) => {
  return [
    {
      image: Team,
      label: strings.authWrapperText.hired,
      value: slide?.hired,
    },
    {
      image: Suitcase,
      label: strings.authWrapperText.jobType,
      value: slide?.jobType,
    },
    {
      icon: BiSolidDollarCircle,
      label: strings.authWrapperText.salary,
      value: slide?.salary,
    },
    {
      icon: MdLocationOn,
      label: strings.authWrapperText.location,
      value: slide?.location,
    },
  ];
};
