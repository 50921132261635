('use client');

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { SLIDES_CONTENT } from '@/constants/auth';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';

import ApplyJobToast from './JobToast';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export interface ISlide {
  title: string;
  _id?: string;
  company: string;
  date: string;
  description: string;
  hired: string;
  client?: string;
  jobType: string;
  salary: string;
  location: string;
}

interface SignupJobCardProps {
  slide?: ISlide;
  loading?: true;
}

const SignupJobCard: React.FC<SignupJobCardProps> = ({ slide, loading }) => {
  const staticText = strings.authWrapperText;
  const { selectedJob, setSelectedJob } = useAuth();
  const [showToast, setShowToast] = useState(false);

  const navigate = useNavigate();

  const handleApplyButton = (job?: ISlide) => {
    setSelectedJob(job!);
    navigate(ROUTES.LOGIN);
    setShowToast(true);
  };

  return (
    <>
      {showToast && (
        <ApplyJobToast
          message={staticText.jobModal.desc.replace(
            '[Job Name]',
            `'<strong>${selectedJob?.title || ''}</strong>'`,
          )}
          duration={5000}
          onClose={() => setShowToast(false)}
        />
      )}
      <div className='relative text-white rounded-2xl bg-white p-7'>
        <div className='flex justify-between items-center mb-1 w-full gap-2 mt-2'>
          <Typography className='text-primaryGreen text-lg md:text-xl font-bold truncate'>
            {loading ? (
              <Skeleton className='w-36 h-4 md:h-6' />
            ) : (
              slide?.company
            )}
          </Typography>
          <Typography className='text-primaryBlack text-sm md:text-[15px] font-semibold opacity-60 min-w-24'>
            {loading ? (
              <Skeleton className='w-28 h-3 md:h-5 mb-1' />
            ) : (
              slide?.date
            )}
          </Typography>
        </div>
        <Typography className='text-primaryBlack text-2xl md:text-[32px] font-extrabold line-clamp-1 pb-1 mb-3'>
          {loading ? (
            <Skeleton className='w-40 h-6 md:h-10 mb-1' />
          ) : (
            slide?.title
          )}
        </Typography>
        <div className='md:min-h-56'>
          <Typography className='text-primaryBlack opacity-70 block md:text-lg bg-blue-200 line-clamp-3'>
            {loading
              ? [...Array(3)].map((_, index) => (
                  <Skeleton
                    className={cn('w-full h-4 md:h-5 mb-1', {
                      'w-3/4': index === 2,
                    })}
                    key={index}
                  />
                ))
              : slide?.description}
          </Typography>
          <div className='space-y-2.5 mt-3'>
            {loading
              ? [...Array(4)].map((_, index) => (
                  <Skeleton className='w-3/5 h-4 md:h-6 mb-1' key={index} />
                ))
              : SLIDES_CONTENT(slide as ISlide).map((item, index) => (
                  <Typography
                    key={index}
                    className='text-primaryBlack opacity-70 md:text-base flex font-semibold'
                  >
                    <span className='text-seaBlueDark mr-1 flex items-center gap-1.5'>
                      {item.image ? (
                        <img
                          src={item.image}
                          alt={item.label}
                          className='h-5 w-6'
                        />
                      ) : null}
                      {item.icon ? <item.icon className='size-6' /> : null}
                      {item.label}:
                    </span>
                    <span className='line-clamp-1'>{item.value}</span>
                  </Typography>
                ))}
          </div>
        </div>
        {loading ? (
          <Skeleton className='w-full h-10 mt-5' />
        ) : (
          <Button
            className='mt-5'
            onClick={() => handleApplyButton(slide as ISlide)}
          >
            {staticText.applyNow}
          </Button>
        )}
      </div>
    </>
  );
};

export default SignupJobCard;
