import React, { useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { X } from 'lucide-react';

import { FieldType, IDropdownOption, OptionalProps } from '@/@types';
import { IFilterOptions } from '@/@types/dashboard';
import DatePickerField from '@/components/DatePickerField';
import ReactDropdown, { DropdownProps } from '@/components/Dropdown';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { INPUT_ERRORS } from '@/constants';
import { generateDashboardFilterFields } from '@/constants/dashboard';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { getCityList, getCountryList } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

interface IFilterField {
  name: string;
  items?: IDropdownOption[] | string[];
  props?: OptionalProps<DropdownProps>;
  isLoading?: boolean | null;
  inputType?: FieldType;
  saveBothLabelAndValue?: boolean;
  fields?: { name: string }[];
}
[];

interface IFilterModalProps {
  openFilterModal: boolean;
  handleResetFilter?: () => void;
  handleApplyFilter?: () => void;
  control: Control<IFilterOptions>;
  filterErrors?: FieldErrors;
  buttonDisabled?: boolean;
  isLoading?: boolean;
  isAnimated?: boolean;
  filters: IFilterOptions;
  handleModalToggle: () => void;
  reset: UseFormReset<IFilterOptions>;
  watch: UseFormWatch<IFilterOptions>;
  setValue: UseFormSetValue<IFilterOptions>;
}

const DashboardFilterModal = ({
  openFilterModal,
  control,
  setValue,
  handleResetFilter,
  handleApplyFilter,
  filterErrors,
  buttonDisabled,
  isLoading,
  isAnimated = true,
  handleModalToggle,
  filters,
  reset,
  watch,
}: IFilterModalProps) => {
  const staticText = strings.filterModal;
  const modalRef = useRef<HTMLDivElement>(null);
  const [searchCountry, setSearchCountry] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const country = watch('assignmentCountry');
  const [formKey, setFormKey] = useState(0);
  const countryList = getCountryList(searchCountry);
  const cityList = getCityList(country as IDropdownOption, searchCity);

  const {
    companies,
    haveMoreCompanyPage,
    department,
    haveMoreDepartmentPage,
    loadingDepartments,
    setCompanyPage,
    setDepartmentPage,
    setDepartmentSearch,
    setCompanySearch,
    loadingCompanies,
  } = useCommonDataContext();

  const filterModalFields: IFilterField[] = generateDashboardFilterFields(
    department,
    loadingDepartments,
    haveMoreDepartmentPage,
    setDepartmentPage,
    setDepartmentSearch,
    companies,
    loadingCompanies,
    haveMoreCompanyPage,
    setCompanyPage,
    setCompanySearch,
    countryList,
    cityList,
    setSearchCountry,
    setSearchCity,
  );

  const [isSmallScreenHeight, setIsSmallScreenHeight] = useState(false);
  const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreenHeight(window.innerHeight < 800);
      setIsSmallScreenWidth(window.innerWidth < 800);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      reset(filters);
    }
  }, [reset, filters]);

  useEffect(() => {
    if (country) {
      setValue('assignmentCity', '');
      setFormKey((prev) => prev + 1);
    }
  }, [country]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        openFilterModal
      ) {
        handleModalToggle();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleModalToggle]);

  return (
    <div
      className={cn(
        'bg-black bg-opacity-50 flex items-center justify-center z-50',
        {
          'h-screen w-screen fixed inset-0':
            openFilterModal && (isSmallScreenHeight || isSmallScreenWidth),
          'sm:w-auto sm:h-auto sm:relative':
            openFilterModal && !(isSmallScreenHeight && isSmallScreenWidth),
        },
      )}
      ref={modalRef}
    >
      {openFilterModal && (
        <div
          className={cn(
            'sm:right-1 py-5 bg-white mx-2 md:mx-0 z-20 shadow-4-side custom-shadow absolute sm:top-[60px] sm:max-w-[500px] rounded-md transition-transform duration-300 ease-in-out',
            {
              'md:animate-slide-up': isAnimated,
              'md:animate-slide-down': !isAnimated,
              'relative': isSmallScreenHeight && isSmallScreenWidth,
            },
          )}
        >
          <Typography variant='xl' className='font-semibold p-5'>
            {staticText.title}
          </Typography>
          <X
            className='h-4 w-4 absolute right-5 top-5 cursor-pointer duration-100 hover:rotate-90'
            onClick={handleModalToggle}
          />
          <div className='gap-x-4 gap-y-3 grid sm:grid-cols-2 mb-3 p-4  mt-1'>
            {filterModalFields.map((field, index) => (
              <div key={`field-${index}`}>
                <Typography className='flex md:text-sm capitalize font-semibold mb-2'>
                  {
                    strings.filterModal[
                      field.name as keyof typeof strings.filterModal
                    ]
                  }
                </Typography>
                {field.inputType === FieldType.DatePicker && (
                  <Controller
                    control={control}
                    name={field.name as keyof IFilterOptions}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <DatePickerField
                          value={value as Date}
                          setValue={onChange}
                          contanierClassName='w-full rounded-md h-11'
                          name={name}
                          errors={filterErrors}
                          isFilterScreen
                        />
                      );
                    }}
                  />
                )}
                {field.inputType === FieldType.Range && (
                  <>
                    <div className='flex gap-3'>
                      <Controller
                        control={control}
                        name={field.fields?.[0]?.name as keyof IFilterOptions}
                        rules={{
                          validate: (value) => {
                            const maxFieldName = field.fields?.[1]
                              ?.name as keyof IFilterOptions;
                            const maxValue = watch(maxFieldName);
                            if (maxValue && (!value || isNaN(Number(value)))) {
                              return INPUT_ERRORS.RANGE.required;
                            }
                            if (value && !maxValue) {
                              return INPUT_ERRORS.RANGE.required;
                            }
                            if (value && isNaN(Number(value))) {
                              return INPUT_ERRORS.RANGE.numberField;
                            }
                            if (
                              maxValue &&
                              value &&
                              parseFloat(value as string) >=
                                parseFloat(maxValue as string)
                            ) {
                              return INPUT_ERRORS.RANGE.validate;
                            }

                            return true;
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => (
                          <Input
                            name={name}
                            placeholder='Min'
                            value={value as string}
                            className='h-11'
                            onChange={onChange}
                            type='number'
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={field.fields?.[1]?.name as keyof IFilterOptions}
                        rules={{
                          validate: (value) => {
                            const minFieldName = field.fields?.[0]
                              ?.name as keyof IFilterOptions;
                            const minValue = watch(minFieldName);
                            if (minValue && (!value || isNaN(Number(value))))
                              return INPUT_ERRORS.RANGE.required;
                            if (
                              minValue &&
                              value &&
                              parseFloat(minValue as string) >=
                                parseFloat(value as string)
                            )
                              return INPUT_ERRORS.RANGE.validate;
                            return true;
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <Input
                              name={name}
                              placeholder='Max'
                              type='number'
                              value={value as string}
                              className='h-11'
                              onChange={onChange}
                            />
                          </>
                        )}
                      />
                    </div>
                    {filterErrors ? (
                      <HookFormErrorMessage
                        errors={filterErrors}
                        name={
                          (field.fields?.[0]?.name as keyof IFilterOptions) ||
                          (field.fields?.[1]?.name as keyof IFilterOptions)
                        }
                        render={({ message }) => (
                          <p className='text-redColor text-xs'>{message}</p>
                        )}
                      />
                    ) : null}
                  </>
                )}
                {field.inputType === FieldType.Input && (
                  <Controller
                    control={control}
                    name={field.name as keyof IFilterOptions}
                    render={({ field: { onChange, value, name } }) => (
                      <Input
                        name={name}
                        placeholder='Type here'
                        value={value as string}
                        className='h-11'
                        onChange={onChange}
                      />
                    )}
                  />
                )}
                {!field.inputType && (
                  <Controller
                    control={control}
                    name={field.name as keyof IFilterOptions}
                    render={({ field: { onChange, value, name } }) => (
                      <ReactDropdown
                        options={field.items ? field.items : []}
                        key={formKey}
                        value={value as string | IDropdownOption}
                        onChange={onChange}
                        isLoading={field.isLoading || false}
                        name={name}
                        isSearchable={field?.props?.isSearchable}
                        inputHeight='44px'
                        haveMoreOptions={field?.props?.haveMoreOptions}
                        setPage={field?.props?.setPage}
                        tagsDropdown={field?.props?.tagsDropdown}
                        setInputSearch={field?.props?.setInputSearch}
                        isDisabled={field?.props?.isDisabled}
                        errors={filterErrors}
                        saveBothLabelAndValue={
                          field?.props?.saveBothLabelAndValue
                        }
                      />
                    )}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='flex items-center justify-center gap-2'>
            <Button
              variant='outline'
              disabled={isLoading || buttonDisabled}
              onClick={handleResetFilter}
              className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            >
              {staticText.btnOneText}
            </Button>
            <Button
              loading={isLoading}
              onClick={handleApplyFilter}
              className={cn(
                'flex md:p-3 lg:p-[22px] sm:w-44 font-medium disabled:opacity-70 sm:text-base md:w-32',
              )}
              disabled={isLoading || buttonDisabled}
            >
              {staticText.btnTwo}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardFilterModal;
