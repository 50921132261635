/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BiSlider } from 'react-icons/bi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { IPaginationResponse } from '@/@types';
import { IFilterOptions, IJobs } from '@/@types/dashboard';
import AiIcon from '@/assets/svgs/AiIcon.svg';
import CircularLoader from '@/components/ClipLoader';
import Container from '@/components/Container';
import SearchBar from '@/components/SearchBar';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  CANDIDATE_FILTER_MODAL_FILEDS,
  DASHBOARD_TABS,
  dashboardInitialFilters,
} from '@/constants/dashboard';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { getJobs } from '@/services/dashboard';
import { EnumToArray } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { useInfiniteQuery } from '@tanstack/react-query';

import AppliedFilterSection from './AppliedFilterSection';
import DashboardFilterModal from './DashboardFilterModal';
import JobCard from './JobCard';

interface Props {
  jobsApplied?: boolean;
}

const JobsSection: React.FC<Props> = ({ jobsApplied }) => {
  const staticText = strings.jobsSection;
  const { tab } = useParams<{ tab?: string }>();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [openFilterModal, setOpenFitlerModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [activeTab, setActiveTab] = useState<DASHBOARD_TABS>(
    DASHBOARD_TABS.ALL,
  );
  const [filters, setFilters] = useState<IFilterOptions>({});
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [jobs, setJobs] = useState<IJobs[]>([]);

  const hasAppliedFilters = Object.values(filters).length;

  const {
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<IFilterOptions>({
    mode: 'all',
    defaultValues: dashboardInitialFilters,
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
    isFetching,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: ['job-list', activeTab],
    queryFn: ({ pageParam = 1 }) =>
      getJobs(activeTab, pageParam.toString(), searchQuery, filters),
    initialPageParam: 1,
    enabled: !!activeTab,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      const newJobs =
        data.pages
          .flatMap((page) => (page as IPaginationResponse<IJobs>).results)
          .filter(Boolean) || [];
      setJobs(newJobs);

      const searchParams = (data.pages[0] as IPaginationResponse<IJobs>)
        ?.searchParams;

      const filteredParams = Object.fromEntries(
        Object.entries(searchParams || {}).filter(
          ([, value]) => value !== null && value !== undefined && value !== '',
        ),
      );
      if (searchQuery.length) {
        if (Object.keys(filteredParams).length > 0) {
          setFilters(searchParams as IFilterOptions);
          setSearchQuery('');
          setSearchText('');
        } else {
          toast.error('No valid filters.');
        }
      }
    }
  }, [isSuccess, isFetching, data]);

  const handleSearchButton = () => {
    setSearchQuery(searchText);
    setShouldRefetch(true);
  };

  const handleRemoveTag = (keyToRemove: keyof IFilterOptions) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[keyToRemove];
      setValue(keyToRemove, '');
      if (
        [
          CANDIDATE_FILTER_MODAL_FILEDS.COMPANY,
          CANDIDATE_FILTER_MODAL_FILEDS.FILTER_COMPANY,
        ].includes(keyToRemove as CANDIDATE_FILTER_MODAL_FILEDS)
      ) {
        updatedFilters.company = '';
        updatedFilters.filterCompany = '';
        setValue(CANDIDATE_FILTER_MODAL_FILEDS.COMPANY, '');
        setValue(CANDIDATE_FILTER_MODAL_FILEDS.FILTER_COMPANY, '');
      }
      if (
        [
          CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT,
          CANDIDATE_FILTER_MODAL_FILEDS.FILTER_DEPARTMENT,
        ].includes(keyToRemove as CANDIDATE_FILTER_MODAL_FILEDS)
      ) {
        updatedFilters.department = '';
        updatedFilters.filterDepartment = '';
        setValue(CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT, '');
        setValue(CANDIDATE_FILTER_MODAL_FILEDS.FILTER_DEPARTMENT, '');
      }
      return updatedFilters;
    });
    setJobs([]);
    setShouldRefetch(true);
  };

  const handleTabChange = (newTab: DASHBOARD_TABS) => {
    setActiveTab(newTab);
    setJobs([]);
    navigate(generatePath(ROUTES.DASHBOARD, { tab: newTab }), {
      replace: true,
    });
  };

  useEffect(() => {
    if (tab && Object.values(DASHBOARD_TABS).includes(tab as DASHBOARD_TABS)) {
      setActiveTab(tab as DASHBOARD_TABS);
    } else {
      const defaultTab = DASHBOARD_TABS.ALL;
      setActiveTab(defaultTab);
      navigate(generatePath(ROUTES.DASHBOARD, { tab: defaultTab }), {
        replace: true,
      });
    }
  }, [tab, navigate]);

  const resetFilter = () => {
    setFilters({});
    reset(dashboardInitialFilters);
    if (hasAppliedFilters) {
      setShouldRefetch(true);
      setJobs([]);
    }
  };

  const handleModalToggle = () => {
    if (openFilterModal) {
      setIsAnimating(true);
      setTimeout(() => {
        setOpenFitlerModal(false);
        setIsAnimating(false);
      }, 300);
    } else {
      setOpenFitlerModal(true);
    }
  };

  const handleFilter: SubmitHandler<IFilterOptions> = (formData) => {
    setFilters({
      ...formData,
      postedOn: getLocalDate(formData?.postedOn as string, 'MMM D, YYYY') || '',
    });
    handleModalToggle();
    setShouldRefetch(true);
    setJobs([]);
  };

  const tags = useMemo(() => {
    if (filters.filterDepartment || filters.filterCompany) {
      return Object.fromEntries(
        Object.entries(filters).filter(
          ([key]) => key !== 'company' && key !== 'department',
        ),
      );
    }
    return filters;
  }, [filters]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetch]);

  const renderContent = () => {
    if (isLoading && !jobs.length) {
      return Array.from({ length: 9 }).map((_, index) => (
        <div
          key={`loading-${index}`}
          className={cn(
            'grid grid-cols-1 sm:grid-cols-3 gap-4 pt-5 items-center justify-center px-5 w-full ',
            { 'sm:grid-cols-2 2xl:grid-cols-3': jobsApplied },
          )}
        >
          {Array.from({
            length: window.innerWidth > 1536 || jobsApplied ? 6 : 8,
          }).map((_, index) => (
            <div key={`loading-${index}`}>
              <JobCard
                isLoading={isLoading}
                activeTab={activeTab}
                className='mb-5'
              />
            </div>
          ))}
        </div>
      ));
    }
    if (!isLoading && !jobs.length && !isFetching) {
      return <StateIndicator state='Empty' />;
    }
    return (
      <InfiniteScroll
        dataLength={jobs.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          <CircularLoader
            className={cn('col-span-1 sm:col-span-3', {
              'sm:grid-cols-2 2xl:grid-cols-3': jobsApplied,
            })}
          />
        }
        scrollableTarget='scrollableDiv'
        className={cn(
          'grid grid-cols-1 sm:grid-cols-3 gap-4 items-center justify-center p-1 w-full',
          { 'sm:grid-cols-2 2xl:grid-cols-3': jobsApplied },
        )}
      >
        {jobs.map((job, index) => (
          <JobCard
            key={job._id || `job-${index}`}
            data={job}
            activeTab={activeTab}
            isLoading={false}
          />
        ))}
      </InfiniteScroll>
    );
  };

  return (
    <>
      <Container className='flex flex-col h-full gap-3 py-2 md:py-1 p-0 md:p-0 border-none'>
        <div className='px-1 pt-2 flex flex-col gap-3'>
          <DashboardFilterModal
            control={control}
            filterErrors={errors}
            isAnimated={isAnimating}
            handleResetFilter={resetFilter}
            openFilterModal={openFilterModal}
            handleModalToggle={handleModalToggle}
            handleApplyFilter={handleSubmit(handleFilter)}
            buttonDisabled={Object.values(watch()).every((value) => !value)}
            isLoading={isFetching}
            filters={filters}
            reset={reset}
            watch={watch}
            setValue={setValue}
          />
          <div className='w-full flex gap-3'>
            <SearchBar
              searchText={searchText}
              setSearchText={setSearchText}
              searchIcon={<img src={AiIcon} className='size-4 sm:size-auto' />}
              placeholder={staticText.searchPlaceholder}
              button={
                <Button
                  className='sm:text-base rounded-md h-9 w-20 disabled:opacity-70'
                  onClick={handleSearchButton}
                  disabled={!searchText.length || isLoading || isFetching}
                  loading={(isLoading || isFetching) && !!searchQuery.length}
                >
                  {staticText.search}
                </Button>
              }
              containerClassName='w-full'
            />
            <Button
              variant='outline'
              className='border-[1.5px] relative p-0 rounded-md sm:rounded-xl size-[42px]'
              onClick={handleModalToggle}
            >
              {hasAppliedFilters ? (
                <div className='size-2 rounded-full absolute -top-0.5 -right-0.5 bg-tomatoRed' />
              ) : null}
              <BiSlider className='text-xl' />
            </Button>
          </div>
          <Typography className='font-bold block' variant='xl'>
            {staticText.title}
          </Typography>
          {Object.values(filters || {}).some((value) => value) ? (
            <AppliedFilterSection
              filters={tags}
              className='mb-3'
              handleRemoveTag={handleRemoveTag}
            />
          ) : null}
        </div>
        <Tabs
          value={activeTab}
          onValueChange={(value) => {
            handleTabChange(value as DASHBOARD_TABS);
          }}
          className='flex flex-col flex-grow'
        >
          <TabsList className='p-0 h-auto gap-2 bg-transparent w-full flex justify-between px-1'>
            <div className='flex gap-2'>
              {EnumToArray(DASHBOARD_TABS).map((item) => (
                <TabsTrigger key={item} value={item} className='capitalize'>
                  {item}
                </TabsTrigger>
              ))}
            </div>
          </TabsList>
          <div className='flex-1 min-h-[500px] xl:min-h-0 relative mt-2'>
            <div className='absolute inset-0 pb-2 scrollbarHidden'>
              <TabsContent value={activeTab} className='h-full'>
                <div
                  id='scrollableDiv'
                  className={cn('h-full', {
                    'overflow-hidden': isLoading || !jobs?.length,
                    'overflow-y-scroll': jobs?.length,
                  })}
                >
                  {renderContent()}
                </div>
              </TabsContent>
            </div>
          </div>
        </Tabs>
      </Container>
    </>
  );
};

export default JobsSection;
