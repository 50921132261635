import React, { useEffect, useState } from 'react';
import {
  Controller,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';

import { IDropdownOption } from '@/@types';
import { ISignUpFields } from '@/@types/auth';
import DatePickerField from '@/components/DatePickerField';
import ReactDropdown from '@/components/Dropdown';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS } from '@/constants';
import { generateSignUpFields, initialValues } from '@/constants/auth';
import { GoogleVerificationURL } from '@/constants/urls';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { academicHistory } from '@/provider/FormProvider/types';
import { ROUTES } from '@/routes';
import { ISignupPayload, signup } from '@/services/auth';
import {
  EmailRegex,
  getAge,
  getCityList,
  getCountryList,
  validateNewPasswordFields,
} from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import GoogleIcon from '../../../assets/svgs/google.svg';
import AuthHeadingsAndDesc from '../components/HeadingAndDesc';
import LineForOrOption from '../components/LineForOrOption';

import AdditionalInfoFields from './AdditionalInfoFields';

const SignUp = () => {
  const { signUp: staticText, common } = strings;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [showAdditionalInfoSection, setHideAdditionalInfoSection] =
    useState(false);

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    clearErrors,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ISignUpFields>({
    mode: 'all',
    defaultValues: initialValues,
  });
  const birthDate = watch('birthDate');
  const age = watch('age');
  const country = watch('country');
  const countryList = getCountryList(searchCountry);
  const cityList = getCityList(country as IDropdownOption, searchCity);

  const PERSONAL_INFO_FIELDS = generateSignUpFields(
    countryList,
    cityList,
    setSearchCountry,
    setSearchCity,
    country && cityList?.length && cityList?.length > 0 ? false : true,
    searchCountry,
    searchCity,
  );

  const signupMutation = useGenericMutation<ISignupPayload, string | boolean>(
    signup,
    {
      onSuccess: (response) => {
        if (typeof response === 'string') {
          toast.success(response, { duration: 5000 });

          navigate(ROUTES.VERIFY_EMAIL, {
            state: { verificationEmail: getValues('email') },
          });
        }
      },
    },
  );

  const handleSignUp: SubmitHandler<ISignUpFields> = (formData) => {
    const payload = {
      ...formData.additionalInfo?.academicHistory,
      workExperiences: formData?.additionalInfo?.workExperiences,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      password: formData?.password,
      civilStatus: formData?.civilStatus,
      contactNo: formData?.contactNo,
      birthDate: formData?.birthDate,
      age: formData?.age,
      country:
        (formData?.country as IDropdownOption)?.label || formData?.country,
      city: (formData?.city as IDropdownOption)?.label || formData?.city || '-',
      block: formData?.block,
      expectedSalary: formData?.additionalInfo?.expectedSalary,
    };
    signupMutation.mutate(payload as ISignupPayload);
  };

  const { newPasswordRules, confirmNewPasswordRules } =
    validateNewPasswordFields(watch('password'));

  const handleGoogleSignUp = () => {
    setLoading(true);
    window.location.href = GoogleVerificationURL;
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 5000);
    }
  }, [loading]);

  useEffect(() => {
    if (watch('password') && watch('reEnterPassword')) {
      trigger('reEnterPassword');
    }
  }, [watch('password'), trigger]);

  const handleInfoSectionBtn = () => {
    setHideAdditionalInfoSection(!showAdditionalInfoSection);
    reset({
      additionalInfo: {
        academicHistory: academicHistory,
        expectedSalary: 0,
        workExperiences: [
          {
            nameOfCompany: '',
            position: '',
            salary: null,
            startDate: '',
            endDate: '',
            reasonForLeaving: '',
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (country) {
      setValue('city', '');
    }
  }, [country]);

  useEffect(() => {
    if (birthDate) {
      setValue('age', getAge(birthDate as string));
    }
  }, [birthDate, age]);

  const getFieldRules = (field: {
    name: string;
    isRequiredMessage?: string;
  }): RegisterOptions<ISignUpFields> => {
    switch (field.name) {
      case 'reEnterPassword':
        return confirmNewPasswordRules as RegisterOptions<
          ISignUpFields,
          'reEnterPassword'
        >;
      case 'contactNo':
        return {
          validate: () => {
            if (!isPhoneValid) {
              return INPUT_ERRORS.CONTACT_NO.validate;
            }
            return true;
          },
        };
      case 'password':
        return newPasswordRules as RegisterOptions<ISignUpFields, 'password'>;
      case 'email':
        return {
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        } as RegisterOptions<ISignUpFields, 'email'>;
      default:
        return {
          required: field.isRequiredMessage,
        } as RegisterOptions<ISignUpFields>;
    }
  };

  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-3'>
          {PERSONAL_INFO_FIELDS.map((field, index) => (
            <React.Fragment key={`${field.name}_${index}`}>
              <div
                className={cn({
                  'col-span-1 sm:col-span-2 md:col-span-1 lg:col-span-2':
                    field.fullWidth,
                })}
              >
                <Controller
                  control={control}
                  name={field.name as keyof ISignUpFields}
                  rules={getFieldRules(field)}
                  render={({ field: { onChange, value, name } }) => {
                    switch (true) {
                      case field.isDropdown:
                        return (
                          <ReactDropdown
                            name={name}
                            title={field?.title}
                            options={field?.dropdownOptions || []}
                            value={value as IDropdownOption}
                            onChange={onChange}
                            className='w-full mt-2 h-12 rounded-md'
                            labelClassName='font-bold md:text-base mb-3'
                            errors={errors}
                            isRequired={field?.isRequired}
                            saveBothLabelAndValue={
                              field?.props?.saveBothLabelAndValue
                            }
                            isLoading={signupMutation.isPending}
                            isSearchable={field?.props?.isSearchable}
                            setInputSearch={field?.props?.setInputSearch}
                            isDisabled={field.isDisabled}
                          />
                        );
                      case field.isDate:
                        return (
                          <div>
                            <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                              {field.title}
                              {field.isRequired ? (
                                <span className='text-redColor text-xl ml-1'>
                                  *
                                </span>
                              ) : null}
                            </Typography>
                            <DatePickerField
                              value={value as Date}
                              setValue={onChange}
                              contanierClassName='w-full rounded-md h-12'
                              name={name}
                              errors={errors}
                              isAgeField={field.name === 'birthDate'}
                            />
                          </div>
                        );
                      case field.contactNumber:
                        return (
                          <div>
                            <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                              {field.title}
                              {field.isRequired ? (
                                <span className='text-redColor text-xl ml-1'>
                                  *
                                </span>
                              ) : null}
                            </Typography>
                            <PhoneInput
                              country={'ph'}
                              enableSearch
                              value={value as string}
                              onChange={(
                                value: string,
                                country: CountryData,
                                _e: React.ChangeEvent<HTMLInputElement>,
                                formattedValue: string,
                              ) => {
                                const { format, dialCode } = country;
                                if (
                                  format?.length === formattedValue?.length &&
                                  (value.startsWith(dialCode) ||
                                    dialCode.startsWith(value))
                                ) {
                                  setPhoneValid(true);
                                } else {
                                  setPhoneValid(false);
                                }
                                setValue(name, value, { shouldValidate: true });
                                if (!isPhoneValid) clearErrors(name);
                              }}
                              inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                              }}
                              inputClass='!h-12 text-base disabled:!bg-greyWhite !w-full !border-greyWhite'
                              containerClass='h-12'
                            />
                            {errors && (
                              <HookFormErrorMessage
                                errors={errors}
                                name={String(name)}
                                render={({ message }) => (
                                  <p className='text-redColor text-xs'>
                                    {message}
                                  </p>
                                )}
                              />
                            )}
                          </div>
                        );
                      default:
                        return (
                          <FormField
                            title={field.title}
                            placeholder={`${common.enter} ${field.title}`}
                            name={name}
                            value={value as keyof ISignUpFields | number}
                            onChange={onChange}
                            errors={errors}
                            labelClassName='font-bold md:text-base mb-3'
                            isRequired={field.isRequired}
                            type={field.isPassword ? 'password' : 'text'}
                            disabled={field.isDisabled}
                          />
                        );
                    }
                  }}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        {showAdditionalInfoSection ? (
          <AdditionalInfoFields
            control={control}
            errors={errors}
            watch={watch}
            loading={signupMutation.isPending}
            handleInfoSectionBtn={handleInfoSectionBtn}
          />
        ) : null}
        {!showAdditionalInfoSection ? (
          <Button
            className='text-sm px-1 md:px-1 w-fit h-fit py-0'
            variant='link'
            onClick={handleInfoSectionBtn}
            disabled={signupMutation.isPending}
          >
            {staticText.addAdditionalInfo}
          </Button>
        ) : null}
        <Button
          className='mt-5 mb-3 text-base'
          onClick={handleSubmit(handleSignUp)}
          loading={signupMutation.isPending}
        >
          {staticText.btnText}
        </Button>
        <LineForOrOption />
        <Button
          variant='ghost'
          className='bg-white font-bold my-3'
          onClick={handleGoogleSignUp}
          loading={loading}
        >
          {strings.continueWithGoogle}
          <img src={GoogleIcon} alt='google' className='ml-3' />
        </Button>
        <div className='w-full flex justify-center'>
          <Typography>
            {staticText.alreadyHaveAnAccount}
            &nbsp;
            <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
              {staticText.signIn}
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SignUp;
