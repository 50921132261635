import { Dispatch, SetStateAction } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FaBuilding } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';

import { FieldType, IDropdownOption, variantType } from '@/@types';
import {
  IDocuments,
  IJobs,
  IResponsePercentage,
  IStatisticsPercentage,
} from '@/@types/dashboard';
import Company from '@/assets/svgs/company.svg';
import Suitcase from '@/assets/svgs/suitcase.svg';
import Team from '@/assets/svgs/team.svg';
import { strings } from '@/locales';
import { formatCurrency } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';

import { INPUT_ERRORS } from '.';

export enum DASHBOARD_TABS {
  ALL = 'all',
  APPLIED = 'applied',
  HIRED = 'hired',
}

export const DOCUMENT_LIST: IDocuments[] = [
  {
    url: '',
    name: 'SSS ID',
    type: '',
    size: '',
    keyName: 'documentSSS',
  },
  {
    url: '',
    name: 'TIN ID',
    type: '',
    size: '',
    keyName: 'documentTIN',
  },
  {
    url: '',
    name: 'Medical Certificate',
    type: '',
    size: '',
    keyName: 'documentMedicalCertificate',
  },
  {
    url: '',
    name: 'NBI Clearance',
    type: '',
    size: '',
    keyName: 'documentNBI',
  },
  {
    url: '',
    name: 'Police Clearance',
    type: '',
    size: '',
    keyName: 'documentPoliceClearance',
  },
  {
    url: '',
    name: 'Barangay Clearance',
    type: '',
    size: '',
    keyName: 'documentBarangayClearance',
  },
  {
    url: '',
    name: 'Birth Certificate',
    type: '',
    size: '',
    keyName: 'documentBirthCertificate',
  },
  {
    url: '',
    name: 'Marriage Certificate',
    type: '',
    size: '',
    keyName: 'documentMarriageCertificate',
  },
  {
    name: 'Government Mandated Scanned ID',
    type: '',
    url: '',
    size: '',
    keyName: 'documentGovernmentID',
  },
  {
    name: 'Certificate of Employment',
    type: '',
    url: '',
    size: '',
    keyName: 'documentCertificateOfEmployment',
  },
  {
    name: 'Diploma or Certificate',
    type: '',
    url: '',
    size: '',
    keyName: 'documentDiplomaOrCertificate',
  },
];

export const createDetailNameAndValues = (detail?: IJobs) => [
  {
    title: strings.jobsSection.department,
    description: detail?.departmentDetails?.name,
  },
  {
    title: strings.jobsSection.country,
    description: detail?.assignmentCountry,
  },
  {
    title: strings.jobsSection.city,
    description: detail?.assignmentCity,
  },
  {
    title: strings.jobsSection.company,
    description: detail?.companyDetails?.name,
  },
  {
    title: strings.jobsSection.createdAt,
    description: getLocalDate(detail?.updatedAt, 'MMM D, YYYY'),
  },
  {
    title: strings.jobsSection.experienceYears,
    description: detail?.experienceYears,
  },
  {
    title: strings.jobsSection.vacancies,
    description: detail?.numberOfHC,
  },
  {
    title: strings.jobsSection.jobType,
    description: detail?.jobType,
  },
  {
    title: strings.jobsSection.typeOfEmployment,
    description: (() => {
      const employmentData = detail?.typeOfEmployment || '{}';
      try {
        const parsedData = JSON.parse(employmentData);
        return Object.values(parsedData)[0] as string;
      } catch (error) {
        return '';
      }
    })(),
  },
  ...(detail?.jobDescription
    ? [
        {
          title: strings.jobsSection.descriptionAndQualification,
          description: detail?.jobDescription,
          className: 'col-span-2 sm:col-span-3 xl:col-span-4 mt-1',
        },
      ]
    : []),
  {
    title: strings.jobsSection.otherDetails,
    className: 'col-span-2 sm:col-span-3 xl:col-span-4',
  },
];
export const APPLIED_FILTERS_PILLS: { [key: string]: variantType } = {
  company: 'blue',
  department: 'pink',
  jobTitle: 'green',
  date: 'purple',
};

export const appliedJobDetail = (
  appliedJobPercentage?: IStatisticsPercentage,
) => [
  {
    name: strings.dashboard.appliedJobs,
    value: appliedJobPercentage?.appliedPercentage || 0,
    percentage: `${appliedJobPercentage?.appliedPercentage}%`,
  },
  {
    name: strings.dashboard.totalJobs,
    value: appliedJobPercentage?.remainingPercentage || 0,
    percentage: `${appliedJobPercentage?.remainingPercentage}%`,
  },
];

export const responsePercentageDetail = (
  responsePercentage?: IResponsePercentage,
) => [
  {
    name: strings.dashboard.responseReceived,
    value: responsePercentage?.receivedPercentage || 0,
    percentage: `${responsePercentage?.receivedPercentage}%`,
  },
  {
    name: strings.dashboard.responsePending,
    value: responsePercentage?.pendingPercentage || 0,
    percentage: `${responsePercentage?.pendingPercentage}%`,
  },
];
export const CONTACT_FORM_FIELDS = [
  {
    name: 'fullName',
    title: strings.contactInfo.name,
    placeholder: strings.contactInfo.namePlaceholder,
    rules: { required: INPUT_ERRORS.NAME.required },
  },
  {
    name: 'contactNumber',
    title: strings.contactInfo.contactNumber,
    placeholder: strings.contactInfo.phoneNumberPlaceholder,
    rules: { required: INPUT_ERRORS.PHONE_NUMBER.required },
  },
  {
    name: 'email',
    title: strings.contactInfo.email,
    placeholder: strings.contactInfo.emailPlaceholder,
  },
  {
    name: 'presentAddress',
    title: strings.contactInfo.presentAddress,
    placeholder: strings.contactInfo.presentAddressPalceholder,
    rules: { required: INPUT_ERRORS.PRESENT_ADDRESS.required },
  },
];
export const generateDashboardFilterFields = (
  department: IDropdownOption[],
  loadingDepartments: boolean | null,
  haveMoreDepartmentPage: boolean | null,
  setDepartmentPage: Dispatch<SetStateAction<number>>,
  setDepartmentSearch: Dispatch<SetStateAction<string>>,
  companies: string[],
  loadingCompanies: boolean | null,
  haveMoreCompanyPage: boolean | null,
  setCompanyPage: Dispatch<SetStateAction<number>>,
  setCompanySearch: Dispatch<SetStateAction<string>>,
  countryList?: IDropdownOption[],
  cityList?: IDropdownOption[],
  setSearchCountry?: React.Dispatch<React.SetStateAction<string>>,
  setSearchCity?: React.Dispatch<React.SetStateAction<string>>,
) => {
  return [
    {
      name: 'department',
      items: department,
      isLoading: loadingDepartments,
      props: {
        haveMoreOptions: !!haveMoreDepartmentPage,
        setPage: setDepartmentPage,
        setInputSearch: setDepartmentSearch,
        isSearchable: true,
      },
    },
    {
      name: 'company',
      items: companies,
      isLoading: loadingCompanies,
      props: {
        haveMoreOptions: !!haveMoreCompanyPage,
        setPage: setCompanyPage,
        setInputSearch: setCompanySearch,
        isSearchable: true,
      },
    },
    {
      name: 'assignmentCountry',
      items: countryList,
      props: {
        setInputSearch: setSearchCountry,
        isSearchable: true,
        saveBothLabelAndValue: true,
      },
    },
    {
      name: 'assignmentCity',
      items: cityList,
      props: {
        setInputSearch: setSearchCity,
        isSearchable: true,
        saveBothLabelAndValue: true,
        isDisabled: !cityList?.length,
      },
    },
    {
      name: 'postedOn',
      inputType: FieldType.DatePicker,
    },

    {
      name: 'experience',
      fields: [{ name: 'minExperience' }, { name: 'maxExperience' }],
      inputType: FieldType.Range,
    },
    {
      name: 'numberOfHc',
      fields: [{ name: 'minNumberOfHc' }, { name: 'maxNumberOfHc' }],
      inputType: FieldType.Range,
    },

    {
      name: 'appliedCandidates',
      fields: [
        { name: 'minAppliedCandidates' },
        { name: 'maxAppliedCandidates' },
      ],
      inputType: FieldType.Range,
    },
  ];
};
export const dashboardInitialFilters = {
  department: '',
  assignmentCountry: '',
  assignmentCity: '',
  postedOn: null,
  company: '',
  minExperience: '',
  maxExperience: '',
  minNumberOfHc: '',
  maxNumberOfHc: '',
  minAppliedCandidates: '',
  maxAppliedCandidates: '',
};
export enum CANDIDATE_FILTER_MODAL_FILEDS {
  COMPANY = 'company',
  FILTER_COMPANY = 'filterCompany',
  DEPARTMENT = 'department',
  FILTER_DEPARTMENT = 'filterDepartment',
}
export const createJobCardDetailsDashboard = (data?: IJobs) => [
  {
    label: strings.jobsSection.department,
    value: data?.departmentDetails?.name,
    icon: FaBuilding,
  },
  {
    label: strings.jobsSection.company,
    value: data?.companyDetails?.name,
    image: Company,
  },
  {
    label: strings.jobsSection.location,
    value: `${data?.assignmentCity}, ${data?.assignmentCountry}`,
    icon: MdLocationOn,
  },
  {
    label: strings.jobsSection.salary,
    value: formatCurrency(data?.rate) || 0,
    icon: BiSolidDollarCircle,
  },
  {
    label: strings.jobsSection.hired,
    value: `${data?.hiredCount} / ${data?.numberOfHC || 0}`,
    image: Team,
  },
  {
    label: strings.jobsSection.jobType,
    value: data?.jobType,
    image: Suitcase,
  },
];
