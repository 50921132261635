import React, { useEffect, useState } from 'react';
import { Control, SubmitHandler, useForm, UseFormWatch } from 'react-hook-form';

import { ISignUpFields, IUser } from '@/@types/auth';
import { IAcademicHistory } from '@/@types/profile';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import AcademicHistoryFields from '@/pages/Auth/components/AcademicHistoryFields';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { academicHistoryStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

const AcademicHistory = () => {
  const { profile: staticText } = strings;
  const { stepTwo, setData, handleNext, handleBack } = useFormContext();
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm<IAcademicHistory>({
    mode: 'all',
    defaultValues: stepTwo || {},
  });

  const postPersonalInfoMutation = useGenericMutation<
    IAcademicHistory,
    IUser | boolean
  >(academicHistoryStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 2,
          data: result?.academicHistory?.[0] as IAcademicHistory,
        });
        handleNext();
        setUser({ ...user, academicHistory: result?.academicHistory } as IUser);
      }
    },
  });

  const onSubmit: SubmitHandler<IAcademicHistory> = (data) => {
    if (
      isDirty &&
      Object.keys(dirtyFields).some((fieldName) => {
        return !!data[fieldName as keyof IAcademicHistory];
      })
    ) {
      postPersonalInfoMutation.mutate(data);
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    if (user?.academicHistory) {
      const educationLevels: Array<{
        key: keyof IAcademicHistory;
        index: number;
      }> = [
        { key: 'college', index: 0 },
        { key: 'vocational', index: 1 },
        { key: 'highSchool', index: 2 },
        { key: 'elementary', index: 3 },
      ];
      educationLevels.forEach(({ key, index }) => {
        const historyEntry = user?.academicHistory?.[index];
        if (historyEntry) {
          setValue(key, historyEntry[key] || '');
          setValue(
            `${key}YearCovered` as keyof IAcademicHistory,
            historyEntry.yearCovered || '',
          );
          setValue(
            `${key}Course` as keyof IAcademicHistory,
            historyEntry.course || '',
          );
        }
      });
    }
  }, [user, reset]);

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.academicHistory}
            </Typography>
            {hasAnyValue(user?.academicHistory) ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          <AcademicHistoryFields
            watch={watch as UseFormWatch<IAcademicHistory | ISignUpFields>}
            control={control as Control<IAcademicHistory | ISignUpFields>}
            errors={errors}
            isEdit={isEdit}
          />
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-end'>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={postPersonalInfoMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            disabled={postPersonalInfoMutation.status === 'pending'}
            loading={postPersonalInfoMutation.status === 'pending'}
            onClick={handleSubmit(onSubmit)}
          >
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AcademicHistory;
