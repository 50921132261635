import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { generatePath, useNavigate } from 'react-router-dom';

import { ILoginResponse, ISignInFields } from '@/@types/auth';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { INPUT_ERRORS } from '@/constants';
import { DASHBOARD_TABS } from '@/constants/dashboard';
import { GoogleVerificationURL } from '@/constants/urls';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import { login } from '@/services/auth';
import { EmailRegex } from '@/utils/common';
import { setTokenToLS, setUserDetailToLS } from '@/utils/localstorage';

import GoogleIcon from '../../../assets/svgs/google.svg';
import AuthHeadingsAndDesc from '../components/HeadingAndDesc';
import LineForOrOption from '../components/LineForOrOption';

const SignIn = () => {
  const staticText = strings.signIn;

  const navigate = useNavigate();
  const { setUser, setToken, selectedJob } = useAuth();

  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['email']);
  const [isRemember, setIsRemember] = useState(false);

  const toggleRemember = () => setIsRemember(!isRemember);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ISignInFields>({
    mode: 'all',
    defaultValues: {
      email: cookies.email || '',
      password: '',
    },
  });

  const loginMutation = useGenericMutation<
    ISignInFields,
    ILoginResponse | boolean
  >(login, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        if (isRemember) {
          setCookie('email', response.user.email, { path: '/' });
        }
        setUser(response?.user);
        setToken(response?.accessToken);
        toast.success(staticText.success);
        setUserDetailToLS(response?.user);
        setTokenToLS(response?.accessToken);
        if (selectedJob) {
          const path = generatePath(ROUTES.JOB_DETAILS, {
            tab: DASHBOARD_TABS.ALL,
            id: selectedJob?._id,
          });
          navigate(path);
        } else {
          navigate(ROUTES.DASHBOARD);
        }
      }
    },
  });

  const handleSignIn: SubmitHandler<ISignInFields> = (formData) => {
    loginMutation.mutate(formData);
  };

  const handleGoogleSignUp = () => {
    setLoading(true);
    window.location.href = GoogleVerificationURL;
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 5000);
    }
  }, [loading]);

  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div className='space-y-4'>
        <Controller
          control={control}
          name='email'
          rules={{
            required: INPUT_ERRORS.EMAIL.required,
            pattern: {
              value: EmailRegex,
              message: INPUT_ERRORS.EMAIL.pattern,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.email}
              placeholder={staticText.emailPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={isSubmitted ? errors : {}}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={{
            required: INPUT_ERRORS.PASSWORD.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.password}
              placeholder={staticText.passwodPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              type='password'
              errors={errors}
              hideInfoIcon
            />
          )}
        />
        <div className='flex justify-between'>
          <div className='flex items-center gap-1'>
            <Checkbox onClick={toggleRemember} />
            <Typography className='text-oceanBlue'>
              {staticText.rememberMe}
            </Typography>
          </div>
          <a
            className='text-base text-primary cursor-pointer'
            href={ROUTES.FORGOT_PASSWORD}
          >
            {staticText.forgotPass}
          </a>
        </div>
        <Button
          className='text-base'
          onClick={handleSubmit(handleSignIn)}
          loading={loginMutation.status === 'pending'}
        >
          {staticText.btnText}
        </Button>
        <LineForOrOption />
        <Button
          variant='ghost'
          className='bg-white font-bold'
          onClick={handleGoogleSignUp}
          loading={loading}
        >
          {strings.continueWithGoogle}
          <img src={GoogleIcon} alt='google' className='ml-3' />
        </Button>
        <div className='w-full flex justify-center'>
          <Typography>
            {staticText.dontHaveAnAccount}
            &nbsp;
            <a className='cursor-pointer text-primary' href={ROUTES.SIGNUP}>
              {staticText.signUp}
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SignIn;
