import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FiFile, FiUploadCloud } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';

import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import { Button } from './ui/button';
import { Input } from './ui/input';
import Container from './Container';
import { Typography } from './Typography';

interface Props {
  buttonText?: string;
  iconClassName?: string;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  fileSize?: number;
  acceptedFileFormat?: string[] | string;
  dropzoneClassName?: string;
  uploadingDivClassName?: string;
  fileName?: string;
  progress?: number;
  isResume?: boolean;
  disabled?: boolean;
}

const mimeTypes: Record<string, string> = {
  '.pdf': 'application/pdf',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc': 'application/msword',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.csv': 'text/csv',
};

const getMimeTypes = (extensions: string[] | string): string[] => {
  if (Array.isArray(extensions)) {
    return extensions.map((ext) => mimeTypes[ext] || '');
  }
  return [mimeTypes[extensions] || ''];
};

const FileDropContainer = forwardRef<
  {
    clearFileUpload: () => void;
  },
  Props
>(
  (
    {
      buttonText,
      iconClassName,
      setFile,
      fileSize = 5,
      acceptedFileFormat = '.docx',
      dropzoneClassName,
      uploadingDivClassName,
      fileName,
      progress = 0,
      disabled = false,
    },
    ref,
  ) => {
    const { common } = strings;
    const FILE_SIZE_LIMIT = fileSize * 1024 * 1024;
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        if (acceptedFiles[0]) {
          const [file] = acceptedFiles;

          if (file.size > FILE_SIZE_LIMIT) {
            toast.error(common.fileSizeError);
          } else {
            setSelectedFile(file);
            setFile(file);
          }
        }
      },
      [FILE_SIZE_LIMIT, setFile, common.fileSizeError],
    );

    const acceptConfig = useMemo(() => {
      const mimeTypesArray = getMimeTypes(
        Array.isArray(acceptedFileFormat)
          ? acceptedFileFormat
          : [acceptedFileFormat],
      );

      return mimeTypesArray.reduce(
        (acc, mimeType) => {
          if (mimeType) {
            acc[mimeType] = Object.keys(mimeTypes).filter(
              (ext) => mimeTypes[ext] === mimeType,
            );
          }
          return acc;
        },
        {} as Record<string, string[]>,
      );
    }, [acceptedFileFormat]);

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      accept: acceptConfig,
      noClick: disabled,
      disabled,
    });

    const handleClearFileUpload = () => {
      setFile(null);
      setSelectedFile(null);
    };

    // Expose the handleClearFileUpload method via ref
    useImperativeHandle(ref, () => ({
      clearFileUpload: handleClearFileUpload,
    }));

    const isImage = (file: File | null) => file?.type.startsWith('image/');

    const renderFilePreview = () => (
      <div
        className={cn(
          'mt-1 h-44 flex gap-2 justify-center items-center bg-simplyViolet rounded-xl cursor-pointer focus:outline-none',
          dropzoneClassName,
        )}
      >
        {isImage(selectedFile) ? (
          <img
            src={URL.createObjectURL(selectedFile!)}
            alt={selectedFile?.name}
            className='size-12 object-cover rounded-lg'
          />
        ) : (
          <div className='p-2 rounded-full bg-primary'>
            <FiFile className='text-2xl text-white' />
          </div>
        )}

        <Typography className='md:text-sm font-semibold truncate max-w-full'>
          {fileName && fileName.length > 30
            ? `${fileName.slice(0, 30)}...`
            : fileName}
        </Typography>

        <button
          className='p-1 rounded-full bg-tomatoRed/15 disabled:cursor-not-allowed'
          onClick={handleClearFileUpload}
          disabled={disabled}
        >
          <HiOutlineTrash className='text-tomatoRed text-base' />
        </button>
      </div>
    );

    const renderFileDropArea = () => (
      <div
        {...getRootProps()}
        className={cn(
          'mt-1 h-44 flex flex-col justify-center items-center bg-simplyViolet rounded-xl cursor-pointer drag-area focus:outline-none',
          dropzoneClassName,
          { 'cursor-not-allowed': disabled },
        )}
        onClick={open}
      >
        <Input {...getInputProps()} disabled={disabled} />
        <FiUploadCloud
          className={cn('text-3xl text-mouseGrey', iconClassName, {
            'opacity-60': disabled,
          })}
        />
        {!buttonText && (
          <Typography className='font-bold text-mouseGrey'>
            {common['drag&drop']}
          </Typography>
        )}
        <Typography className='md:text-sm text-medium text-mouseGrey flex items-center w-fit'>
          {!buttonText ? common.or : ''}
          <Button
            variant='link'
            className='p-0 m-1 w-fit h-8'
            type='button'
            disabled={disabled}
          >
            {buttonText || common.browseFile}
          </Button>
          {!buttonText ? common.fromDevice : ''}
        </Typography>
      </div>
    );

    const renderProgressView = () => (
      <Container height='h-fit' className={cn('mt-1', uploadingDivClassName)}>
        <Typography className='md:text-sm font-semibold truncate max-w-full'>
          {fileName && fileName.length > 30
            ? `${fileName.slice(0, 30)}...`
            : fileName}
        </Typography>
        <div className='flex items-center gap-2'>
          <Typography className='md:text-sm font-semibold text-quickSilver'>
            {Math.min(Math.max(progress, 0), 100)}%
          </Typography>
          <div className='size-1 rounded-full bg-quickSilver' />
          <Typography className='md:text-sm font-semibold text-quickSilver'>
            {progress < 100
              ? `${Math.ceil(((100 - progress) / 100) * 30)} seconds remaining`
              : common.uploadComplete}
          </Typography>
        </div>
        <div className='mt-4 h-2 overflow-hidden rounded-full bg-quickSilver/60'>
          <div
            className='h-5 transition-all ease-in-out bg-primary'
            style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
          />
        </div>
      </Container>
    );

    if (progress !== 0) return renderProgressView();
    if (!selectedFile) return renderFileDropArea();
    return renderFilePreview();
  },
);

FileDropContainer.displayName = 'FileDropContainer';

export default FileDropContainer;
