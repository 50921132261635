import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CgDanger } from 'react-icons/cg';
import { useNavigate, useParams } from 'react-router-dom';

import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import { IJobs } from '@/@types/dashboard';
import BackHeader from '@/components/BackHeader';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import DownloadableFileRow from '@/components/DownloadableFileRow';
import FileDropContainer from '@/components/FileDropContainer';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import Tags from '@/components/Tags';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import {
  createDetailNameAndValues,
  DOCUMENT_LIST,
} from '@/constants/dashboard';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import {
  backoutCandidate,
  fetchJobDetail,
  getDocuments,
} from '@/services/dashboard';
import { uploadResume } from '@/services/profile';
import { getFileNameFromUrl, getTagVariant, hasAnyValue } from '@/utils/common';

import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';
// import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';
import ContactInfoModal from '../Dashboard/components/ContactInfoModal';
import ReferFriendModal from '../Dashboard/components/ReferFriendModal';
import UploadFileModal from '../Dashboard/components/UploadFileModal';

import ResumeCard from './ResumeCard';

export interface FileDropContainerRef {
  clearFileUpload: () => void;
}

const JobDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [openUploadFileModal, setUploadFileModal] = useState(false);
  const [fileName, setFileName] = useState('');
  const [resumeProgress, setResumeProgress] = useState(0);
  const [resume, setResume] = useState<File | null>(null);
  const { user, selectedJob, setSelectedJob, setUser } = useAuth();
  const navigate = useNavigate();
  const fileDropRef = useRef<FileDropContainerRef>(null);

  const { jobsSection: staticText, common } = strings;

  const {
    data,
    isLoading,
    refetch: refetchDetails,
  } = useGenericQuery(['job-detail', id], () => fetchJobDetail(id!));
  const detail = data as IJobs;

  const handleUploadFilModalOpen = (
    e: { preventDefault: () => void },
    name: string,
  ) => {
    setUploadFileModal(true);
    setFileName(name);
    e.preventDefault();
  };

  const {
    data: documents,
    isLoading: loadingDocuments,
    refetch,
  } = useGenericQuery(['documents', id], () => getDocuments());

  const filledDocumentList = DOCUMENT_LIST.map((dummyDoc) => {
    const matchingDocument = Array.isArray(documents)
      ? documents.find((doc) => doc.name === dummyDoc.name)
      : null;
    return {
      ...dummyDoc,
      url: matchingDocument?.url || '',
      type: matchingDocument?.type || '',
    };
  });

  const filteredDocumentList = filledDocumentList
    ?.filter((item) => (detail?.requiredDocs ?? []).includes(item.name))
    .sort((a) => (a.url ? -1 : 1));

  const handleApplyOrBackoutButton = () => {
    if (detail?.isApplied && !detail?.candidateTags?.[0]?.backedOut) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsContactModalOpen(true);
    }
  };

  const backoutMutation = useGenericMutation<string, boolean>(
    backoutCandidate,
    {
      onSuccess: (response) => {
        if (response) {
          setIsConfirmationModalOpen(false);
          refetchDetails();
        }
      },
    },
  );

  const handleResumeProgress = useCallback((percentage: number) => {
    setResumeProgress(percentage);
  }, []);

  const handleBackout = () => {
    backoutMutation.mutate(detail?._id);
  };

  const buttonText = useMemo(() => {
    if (detail?.isApplied && !detail?.candidateTags?.[0]?.backedOut) {
      return staticText.backout;
    } else if (detail?.candidateTags?.[0]?.backedOut) {
      return staticText.reApply;
    }
    return staticText.apply;
  }, [detail]);

  const handleCancelOrRefer = () => {
    if (resume) {
      fileDropRef.current?.clearFileUpload();
    } else {
      setIsReferModalOpen(true);
    }
  };

  const uploadResumeMutation = useGenericMutation<
    { resume: File } & {
      setResumeProgress?: (progress: number) => void;
    },
    ICandidate | boolean
  >(uploadResume, {
    onSuccess: (result) => {
      setUser({
        ...user,
        personalInfo: {
          ...user?.personalInfo,
          resume: (result as ICandidate).resume,
          fileSize: (result as ICandidate).fileSize,
          uploadedDate: (result as ICandidate).uploadedDate,
        },
      } as IUser);
      fileDropRef.current?.clearFileUpload();
    },
  });

  const handleResumeUpload = () => {
    setResumeProgress(0);
    uploadResumeMutation.mutate({
      resume: resume as File,
      setResumeProgress: handleResumeProgress,
    });
  };

  const getButtonText = (): string => {
    if (detail?.isHired) return staticText.hired;
    if (!user?.personalInfo?.resume) return staticText.tooltipForResume;
    return staticText.tooltipForDisableButton;
  };

  const isPreDeployStep =
    detail?.candidateTags?.[0]?.status === CANDIDATE_STATUS.PRE_DEPLOY ||
    detail?.candidateTags?.[0]?.status === CANDIDATE_STATUS.HR_ORIENTATION;

  useEffect(() => {
    if (selectedJob) {
      setSelectedJob(null);
    }
  }, []);

  return (
    <>
      <div>
        <div className='flex justify-between gap-3 flex-col md:flex-row'>
          <BackHeader
            title={detail?.jobTitle || ''}
            loading={isLoading}
            handleBackBtn={() => navigate(ROUTES.DASHBOARD)}
          />
          <div className='flex gap-1 md:gap-3 items-center justify-end'>
            {detail?.candidateTags?.[0]?.status ? (
              <Tags
                text={
                  detail?.candidateTags?.[0]?.status ===
                  CANDIDATE_STATUS.HR_ORIENTATION
                    ? 'Hired'
                    : detail?.candidateTags?.[0]?.status
                }
                variant={getTagVariant(detail?.candidateTags?.[0]?.status)}
                containerClassName='w-36 h-10 rounded-lg'
                isLoading={isLoading}
              />
            ) : null}
            <Button
              variant='outline'
              className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
              onClick={handleCancelOrRefer}
            >
              {resume ? common.cancel : staticText.refer}
            </Button>
            {resume ? (
              <Button
                className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
                onClick={handleResumeUpload}
                loading={uploadResumeMutation.isPending}
                disabled={uploadResumeMutation.isPending}
              >
                {common.save}
              </Button>
            ) : (
              <TooltipComponent
                align='center'
                className='py-2 px-4 text-xs mr-5'
                showTooltip={
                  !hasAnyValue(user?.personalInfo) ||
                  !hasAnyValue(user?.academicHistory) ||
                  detail?.isHired ||
                  !user?.personalInfo?.resume
                }
                trigger={
                  <Button
                    className='sm:w-44 md:w-32'
                    disabled={
                      !hasAnyValue(user?.personalInfo) ||
                      !hasAnyValue(user?.academicHistory) ||
                      isLoading ||
                      detail?.isHired ||
                      !user?.personalInfo?.resume
                    }
                    onClick={handleApplyOrBackoutButton}
                  >
                    {buttonText}
                  </Button>
                }
              >
                {getButtonText()}
              </TooltipComponent>
            )}
          </div>
        </div>
        <Container className='h-auto md:px-9 md:py-7 mt-5 space-y-5'>
          <Typography variant='xl' className='font-semibold'>
            {staticText.resume}
          </Typography>
          {user?.personalInfo?.resume ? (
            <ResumeCard
              file={{
                name: isLoading
                  ? ''
                  : getFileNameFromUrl(
                      (user?.personalInfo?.resume as string) || '',
                    ),
                size: (user?.personalInfo?.fileSize as string) || '-',
                url: (user?.personalInfo?.resume as string) || '',
                date: (user?.personalInfo?.uploadedDate as string) || '-',
              }}
              isLoading={isLoading}
            />
          ) : (
            <FileDropContainer
              buttonText='Upload Resume'
              setFile={setResume}
              fileName={resume ? resume.name : ''}
              dropzoneClassName='w-full h-28 p-2'
              uploadingDivClassName='w-full h-28'
              iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
              acceptedFileFormat={['.pdf', '.docx']}
              isResume
              disabled={uploadResumeMutation.isPending}
              ref={fileDropRef}
              progress={resume ? 0 : resumeProgress}
            />
          )}
        </Container>
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-4 mt-5'>
          <Container
            className={cn('col-span-full', {
              'col-span-1 md:col-span-3 lg:col-span-5': isPreDeployStep,
            })}
          >
            <div className='grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-5 gap-y-7'>
              <div>
                <Typography className='block font-bold md:text-[15px] mb-1'>
                  {staticText.name}
                </Typography>
                <ProfileBadge
                  name={`${detail?.authorDetails?.firstName} ${detail?.authorDetails?.lastName}`}
                  profilePicture={detail?.authorDetails?.avatar || ''}
                  className='justify-start'
                  isLoading={isLoading}
                />
              </div>
              {createDetailNameAndValues(detail).map(
                ({ title, description, className }, index) => {
                  if (title === strings.jobsSection.otherDetails) {
                    const hasOtherDetails =
                      detail?.qualifications?.length ||
                      detail?.qualificationsDetails;

                    return (
                      <div className={className} key={index}>
                        {hasOtherDetails && (
                          <>
                            <Typography
                              variant='heading'
                              className='md:text-[15px]'
                            >
                              {strings.jobsSection.otherDetails}
                            </Typography>

                            <div className='flex gap-2 flex-col'>
                              {detail?.qualificationsDetails && (
                                <Typography>
                                  {detail.qualificationsDetails}
                                </Typography>
                              )}
                              <div className='flex flex-wrap gap-3'>
                                {detail?.qualifications?.map(
                                  (attachment, index) => (
                                    <AttachmentPreview
                                      key={`attachment-${index}`}
                                      attachment={attachment as string}
                                    />
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  }
                  return (
                    <NameAndDesc
                      key={index}
                      title={title}
                      description={description}
                      isLoading={isLoading}
                      className={className}
                    />
                  );
                },
              )}
            </div>
          </Container>
          {isPreDeployStep ? (
            <Container className='col-span-1 md:col-span-3 lg:col-span-2'>
              <Typography variant='subheading' className='font-bold'>
                {staticText.requiredDocument}
              </Typography>
              <div className='mt-3 flex flex-col gap-y-2'>
                {loadingDocuments || isLoading ? (
                  <div className='min-h-60 flex items-center justify-center'>
                    <StateIndicator state='Loading' />
                  </div>
                ) : (
                  <>
                    {filteredDocumentList.length === 0 ? (
                      <Typography className='mt-4 text-center text-mouseGrey'>
                        {strings.common.noData}
                      </Typography>
                    ) : (
                      filteredDocumentList
                        .sort((a) => (a.url ? -1 : 1))
                        .map((item) => (
                          <DownloadableFileRow
                            key={item.name}
                            name={item.name}
                            url={item.url}
                            onUploadIconClick={(e) => {
                              handleUploadFilModalOpen(e, item.name);
                            }}
                          />
                        ))
                    )}
                  </>
                )}
              </div>
            </Container>
          ) : null}
        </div>
      </div>
      <ReferFriendModal
        handleClose={() => setIsReferModalOpen(false)}
        open={isReferModalOpen}
      />
      <ContactInfoModal
        open={isContactModalOpen}
        handleClose={() => setIsContactModalOpen(false)}
        jobId={id!}
      />
      <UploadFileModal
        open={openUploadFileModal}
        handleCloseModal={() => setUploadFileModal(false)}
        fileName={fileName}
        refetch={refetch}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        primaryBtnLabel={staticText.backout}
        primaryBtnAction={handleBackout}
        textDesc={staticText.backoutText}
        titleClassName='h-20 bg-red-200'
        isBackoutModal
        isLoading={backoutMutation.isPending}
        icon={<CgDanger className='size-6 text-tomatoRed' />}
      />
    </>
  );
};

export default JobDetails;
