import * as React from 'react';
import { FieldErrors } from 'react-hook-form';
import { HiMiniCalendarDays } from 'react-icons/hi2';
import { format, isAfter, isBefore, subYears } from 'date-fns';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

export interface Props {
  value: Date | undefined;
  setValue: React.Dispatch<React.SetStateAction<Date | undefined>>;
  contanierClassName?: string;
  errors?: FieldErrors;
  name?: string;
  disabled?: boolean;
  isAgeField?: boolean;
  isFilterScreen?: boolean;
}

const DatePickerField: React.FC<Props> = ({
  value,
  setValue,
  contanierClassName,
  errors,
  name,
  disabled,
  isAgeField = false,
  isFilterScreen,
}) => {
  const staticText = strings.common;

  // Calculate maximum and minimum dates for selectable range based on age and filter conditions
  const maxDate = isAgeField ? subYears(new Date(), 19) : new Date();
  const minDate = isAgeField
    ? subYears(new Date(), 100)
    : new Date('1900-01-01');

  return (
    <>
      <Popover>
        <PopoverTrigger asChild disabled={disabled}>
          <Button
            icon={
              <HiMiniCalendarDays className='ml-2 size-4 shrink-0 text-quickSilver' />
            }
            variant={'outline'}
            disabled={disabled}
            className={cn(
              'justify-start disabled:bg-greyWhite text-left font-normal border-greyWhite w-[200px] rounded-xl h-[42px] text-sm text-primaryBlack px-1 bg-white border-[1px]',
              !value && 'text-muted-foreground',
              contanierClassName,
            )}
            textWithIconClassName='justify-between'
          >
            {value ? (
              format(value, 'PPP')
            ) : (
              <span className='text-quickSilver text-[15px] font-semibold'>
                {staticText.select}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='flex w-auto flex-col space-y-2 p-2'>
          <Calendar
            mode='single'
            selected={value}
            onSelect={setValue}
            disabled={(date) => {
              // Disable dates outside the last 19 years if isAgeField is true
              if (isAgeField) {
                return isAfter(date, maxDate) || isBefore(date, minDate);
              }
              // Disable future dates if isFilterScreen is true
              if (isFilterScreen) {
                return isAfter(date, new Date());
              }
              return false;
            }}
            isBirthDate={isAgeField}
          />
        </PopoverContent>
      </Popover>
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </>
  );
};

export default DatePickerField;
