import toast from 'react-hot-toast';
import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { IPaginationResponse } from '@/@types';
import {
  IAppliedJob,
  IApplyJobData,
  IDocuments,
  IFilterOptions,
  IJobs,
  IResponsePercentage,
  IStatisticsPercentage,
  IUploadDocument,
} from '@/@types/dashboard';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { ContactInfoFormData } from '@/pages/Dashboard/components/ContactInfoModal';
import { IFormData } from '@/pages/MyDocuments';
import { buildQueryParams } from '@/utils/common';

export const getJobs = async (
  type: string,
  page: string,
  search?: string,
  filters?: IFilterOptions,
): Promise<IPaginationResponse<IJobs> | boolean> => {
  const processedFilters = filters
    ? Object.fromEntries(
        Object.entries(filters)?.map(([key, filter]) => {
          if (filter.label && filter.value) {
            return [key, filter.label];
          }
          return [key, filter];
        }),
      )
    : null;
  const queryParams = buildQueryParams({
    type,
    page,
    search,
    ...processedFilters,
  });
  const response = await API.Get<IPaginationResponse<IJobs>>(
    `${REST_SUB_URL.GET_ALL_JOBS}?${queryParams}`,
  );

  if (response.status && response?.data) {
    return response.data as IPaginationResponse<IJobs>;
  }

  toast.error(response.message);
  return false;
};

export const fetchJobDetail = async (id: string): Promise<IJobs | boolean> => {
  const response = await API.Get<IJobs>(`${REST_SUB_URL.GET_ALL_JOBS}/${id}`);
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const getJobAppliedPercentage = async (): Promise<
  IStatisticsPercentage | boolean
> => {
  const response = await API.Get<IStatisticsPercentage>(
    `${REST_SUB_URL.GET_STATISTICS}`,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const getResponsePercentage = async (): Promise<
  IResponsePercentage | boolean
> => {
  const response = await API.Get<IResponsePercentage>(
    `${REST_SUB_URL.GET_RESPONSE_PERCENTAGE}`,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const applyJob = async ({
  jobId,
  payload,
}: IApplyJobData): Promise<boolean> => {
  const response = await API.Patch<ContactInfoFormData, boolean>(
    `${REST_SUB_URL.JOB_APPLY}?jobId=${jobId}`,
    payload,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }
  toast.error(response.message);
  return false;
};

export const getDocuments = async (): Promise<IDocuments[] | boolean> => {
  const response = await API.Get<IDocuments[]>(`${REST_SUB_URL.DOCUMENTS}`);

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const uploadDocument = async (
  payload: IUploadDocument & { setPercent?: (progress: number) => void },
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', payload.file as File);
  formData.append('name', payload.name);

  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (payload.setPercent && progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        payload.setPercent(percentCompleted);
      }
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await API.Post<FormData, IUploadDocument>(
    REST_SUB_URL.DOCUMENTS,
    formData,
    config,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }
  toast.error(response.message);
  return false;
};

export const deleteDocument = async (
  fileName: string,
): Promise<boolean | boolean> => {
  const response = await API.Delete<string, boolean>(
    `${REST_SUB_URL.DOCUMENTS}/${fileName}`,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }

  toast.error(response.message);
  return false;
};

export const uploadMultipleDocument = async (
  payload: IFormData & { setPercent?: (percent: number) => void },
): Promise<boolean> => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (key !== 'setPercent' && value) {
      // Exclude setPercent from formData
      formData.append(key, value as Blob | string);
    }
  });

  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (payload.setPercent && progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        payload.setPercent(percentCompleted); // Call the progress update function
      }
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await API.Post<FormData, IFormData>(
    REST_SUB_URL.DOCUMENTS_MULTIPLE,
    formData,
    config,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }
  toast.error(response.message);
  return false;
};

export const backoutCandidate = async (id: string): Promise<boolean> => {
  const response = await API.Patch<string, boolean>(
    `${REST_SUB_URL.JOB_BACKOUT}/?jobId=${id}`,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }
  toast.error(response.message);
  return false;
};

export const getSliderData = async (): Promise<IAppliedJob[] | boolean> => {
  const response = await API.Get<IAppliedJob[]>(
    REST_SUB_URL.APPLIED_JOBS_SLIDER,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};
